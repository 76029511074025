@use "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

// .media-page {
//   @include mixins.borders;
//   padding-bottom: 60px;

//   @media (max-width: $tablet) {
//     padding-top: 120px;
//   }
// }

.media {
  padding-bottom: 50px;

  & > .separator {
    margin-bottom: 50px;

    &.separator-down {
      &::before {
        transform: translateX(-50%) scale(3) rotate(180deg);
      }
      @media (max-width: $mobile) {
        margin-top: 50px;
      }
    }
  }
}

.currentPhotoModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background-color: #000000;

  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $mobile) {
    display: none;
  }

  img {
    width: 80%;

    border: 3px solid #e3d1ad;
  }
}

.media__control-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-bottom: 30px;
  margin-top: 10px;

  @media (max-width: $tablet) {
    margin-top: 0;
    justify-content: space-between;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
  }

  & .separator {
    flex-basis: 100%;
    margin-top: 12px;
    order: 10;
    margin-top: 50px;

    @media (max-width: $mobile) {
      order: -2;
      margin-top: 3px;
      margin-bottom: 40px;
      flex-basis: auto;
    }
  }
}

.media__control-title {
  @include mixins.h2;
  width: auto;
  margin-top: 10px;
  margin-right: calc(5vw + 20px);
  margin-left: calc(5vw + 20px);
  position: relative;

  @media (max-width: $tablet) {
    order: -2;
    flex-basis: 100%;
    margin-bottom: 25px;
  }

  &::before,
  &::after {
    content: $venzel-1;
    position: absolute;
    transform: translateY(-50%) scale(3);
    top: 22px;

    @media (max-width: $tablet) {
      display: none;
    }
  }
  &::before {
    left: calc(-2.5vw - 15px);
  }

  &::after {
    right: calc(-2.5vw - 15px);
  }
}

.control_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40;

  @media (max-width: $mobile) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.photo_control {
  @media (max-width: $mobile) {
    order: 3;
  }
}

.video_control {
  @media (max-width: $mobile) {
    margin-bottom: 15px;
    border-top: 1px solid #e3d1ad;
    padding-top: 25px;
    margin-top: 30px;
    position: relative;
    &::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='20' height='20' style='shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;fill-rule:evenodd;clip-rule:evenodd' viewBox='0 0 170 208.1'%3E%3Cpath d='M36.5 208c18-6.1 34.8-13.7 47.8-26.8 10.7 12.4 27.4 20.3 46 26.8-11-10.9-40.6-55-40.6-69 1.5-27.2 19.7-42 37.3-42.5a30.6 30.6 0 0 1 26.7 14.3 30.6 30.6 0 0 0-11.5-2.1c-7.6.2-14.9 3.3-20.4 9a30 30 0 0 0-8.9 20.3 27.5 27.5 0 0 0 28.6 27.7c14.6-.3 26.8-13 28.3-27.3.3 0 0-2.7 0-3-.4-34-22-49-42.7-48.5-8.2.3-16.6 2.8-24 7.8C117 59 112.5 27 85.4 0 56.4 29 55.3 57.8 68.2 95c-7-4-15.6-8.5-23.7-8.2a40.4 40.4 0 0 0-29 12.3A57 57 0 0 0 0 139.9a27.5 27.5 0 0 0 28.5 25.8c15.3-.4 28.1-14 28.5-29.2.5-15.7-12-28.2-27.8-27.8-4 .1-8 1.2-11.5 2.8a31.7 31.7 0 0 1 26.6-15c17.6-.4 35.8 15.7 35.6 43-.3 14.7-32 57.1-43.4 68.6zM142 118.5a17.7 17.7 0 0 1 18.2 17.3v1.5c-.8 10.3-8 18.5-18.5 18.9-26.8.5-23.1-37.3.2-37.7zm-56.6-8c-9-16.7-30.6-61.4 0-96.4 28 32.7 9.6 79 0 96.3zM29 118.4a17.6 17.6 0 0 1 18.3 18.3c-.3 10.7-7.3 19-18.5 19.1-12.8.5-18.6-8.7-18.8-18a18.7 18.7 0 0 1 19-19.4z' style='fill:%23e3d1ad'/%3E%3C/svg%3E");
      height: 20px;
      width: 80px;

      background-color: #240d08;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: -10px;
      left: calc(50% - 40px);
    }
  }
}

.control_button {
  background-color: transparent;
  /* border: none; */
  font-family: "Lazurski";
  width: auto;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  padding: 10px 15px 7px;
  display: inline;
  color: #e3d1ad;
  text-transform: uppercase;
  margin: 5px;
  -webkit-box-shadow: 0 0 0 2px #ae967c, 0 0 0 4px #2e1005;
  box-shadow: 0 0 0 2px #ae967c, 0 0 0 4px #2e1005;
  background-color: rgba(227, 209, 173, 0.1);
  -webkit-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  font-size: 20px;
  width: 100%;
}

.media__control_item {
  flex: 1 0 30%;
  width: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: $tablet) {
    flex: 0 1 40%;
  }

  &--photo {
    order: -2;

    @media (max-width: $mobile) {
      order: 3;
    }

    & > button.pressed {
      & + .media__control-dates {
        display: flex;
      }
    }
  }

  &--video {
    @media (max-width: $mobile) {
      order: -2;
      margin-bottom: 20px;
    }
  }
}

.media__control-button {
  background-color: transparent;
  border: none;
  @include mixins.button;
  font-size: 20px;
  width: 100%;

  &.pressed {
    background-color: rgba($color-3, 0.4);
    box-shadow: 0px 0px 0px 2px $color-3, 0px 0px 0px 4px $color-4,
      0 0 7px 3px rgba($color-2, 1);
  }
}

.media__control-years {
  // display: none;
  // display: flex;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  // justify-content: space-evenly;
  // justify-content: flex-start;
  width: 100%;
  margin-top: 15px;

  @media (max-width: $tablet) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  button {
    border: none;
    @include mixins.button;
    white-space: nowrap;

    @media (max-width: $tablet) {
      padding-left: 5px;
      padding-right: 5px;
      // margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: $mobile) {
      flex-basis: 30%;
    }

    &.pressed {
      background-color: rgba($color-3, 0.4);
      box-shadow: 0px 0px 0px 2px $color-3, 0px 0px 0px 4px $color-4,
        0 0 7px 3px rgba($color-2, 1);
    }
  }
}

.media__control-dates {
  // display: none;
  // display: flex;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  // justify-content: space-evenly;
  // justify-content: flex-start;
  width: 100%;
  margin-top: 15px;

  @media (max-width: $tablet) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  button {
    border: none;
    @include mixins.button;
    white-space: nowrap;

    @media (max-width: $tablet) {
      padding-left: 5px;
      padding-right: 5px;
      // margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: $mobile) {
      flex-basis: 30%;
    }

    &.pressed {
      background-color: rgba($color-3, 0.4);
      box-shadow: 0px 0px 0px 2px $color-3, 0px 0px 0px 4px $color-4,
        0 0 7px 3px rgba($color-2, 1);
    }
  }
}

// photo
/* ====================================== */

.yearsControl {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin: 15px 0;

  @media (max-width: $mobile) {
    grid-template-columns: repeat(4, 1fr);
  }
  button {
    width: 100%;
    border: none;
    font-family: "Lazurski";
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    padding: 10px 15px 7px;
    display: inline;
    color: #e3d1ad;
    text-transform: uppercase;
    margin: 5px;
    -webkit-box-shadow: 0 0 0 2px #ae967c, 0 0 0 4px #2e1005;
    box-shadow: 0 0 0 2px #ae967c, 0 0 0 4px #2e1005;
    background-color: rgba(227, 209, 173, 0.1);
    -webkit-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    white-space: nowrap;
  }
}

.control_title {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  h1 {
    font-family: Lazurski;
    font-size: 24px;
    line-height: 32px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    position: relative;
    margin: 0 20px;
  }

  img {
    margin-top: 22px;
    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.daysControl {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 15px;

  @media (max-width: $mobile) {
    grid-template-columns: repeat(2, 1fr);
  }

  button {
    width: 100%;
    border: none;
    font-family: "Lazurski";
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    padding: 10px 15px 7px;
    display: inline;
    color: #e3d1ad;
    text-transform: uppercase;
    margin: 5px;
    -webkit-box-shadow: 0 0 0 2px #ae967c, 0 0 0 4px #2e1005;
    box-shadow: 0 0 0 2px #ae967c, 0 0 0 4px #2e1005;
    background-color: rgba(227, 209, 173, 0.1);
    -webkit-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    white-space: nowrap;
  }
}

.photos_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;

  border-bottom: 1px solid #e3d1ad;
  border-top: 1px solid #e3d1ad;

  padding: 30px 0;
  margin-top: 40px;

  position: relative;

  @media (max-width: $mobile) {
    grid-template-columns: repeat(1, 1fr);
  }

  &::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='20' height='20' style='shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;fill-rule:evenodd;clip-rule:evenodd' viewBox='0 0 170 208.1'%3E%3Cpath d='M36.5 208c18-6.1 34.8-13.7 47.8-26.8 10.7 12.4 27.4 20.3 46 26.8-11-10.9-40.6-55-40.6-69 1.5-27.2 19.7-42 37.3-42.5a30.6 30.6 0 0 1 26.7 14.3 30.6 30.6 0 0 0-11.5-2.1c-7.6.2-14.9 3.3-20.4 9a30 30 0 0 0-8.9 20.3 27.5 27.5 0 0 0 28.6 27.7c14.6-.3 26.8-13 28.3-27.3.3 0 0-2.7 0-3-.4-34-22-49-42.7-48.5-8.2.3-16.6 2.8-24 7.8C117 59 112.5 27 85.4 0 56.4 29 55.3 57.8 68.2 95c-7-4-15.6-8.5-23.7-8.2a40.4 40.4 0 0 0-29 12.3A57 57 0 0 0 0 139.9a27.5 27.5 0 0 0 28.5 25.8c15.3-.4 28.1-14 28.5-29.2.5-15.7-12-28.2-27.8-27.8-4 .1-8 1.2-11.5 2.8a31.7 31.7 0 0 1 26.6-15c17.6-.4 35.8 15.7 35.6 43-.3 14.7-32 57.1-43.4 68.6zM142 118.5a17.7 17.7 0 0 1 18.2 17.3v1.5c-.8 10.3-8 18.5-18.5 18.9-26.8.5-23.1-37.3.2-37.7zm-56.6-8c-9-16.7-30.6-61.4 0-96.4 28 32.7 9.6 79 0 96.3zM29 118.4a17.6 17.6 0 0 1 18.3 18.3c-.3 10.7-7.3 19-18.5 19.1-12.8.5-18.6-8.7-18.8-18a18.7 18.7 0 0 1 19-19.4z' style='fill:%23e3d1ad'/%3E%3C/svg%3E");
    transform: rotate(180deg);
    height: 40px;
    width: 80px;

    background-color: #240d08;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: calc(100% - 20px);
    left: calc(50% - 40px);
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='20' height='20' style='shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;fill-rule:evenodd;clip-rule:evenodd' viewBox='0 0 170 208.1'%3E%3Cpath d='M36.5 208c18-6.1 34.8-13.7 47.8-26.8 10.7 12.4 27.4 20.3 46 26.8-11-10.9-40.6-55-40.6-69 1.5-27.2 19.7-42 37.3-42.5a30.6 30.6 0 0 1 26.7 14.3 30.6 30.6 0 0 0-11.5-2.1c-7.6.2-14.9 3.3-20.4 9a30 30 0 0 0-8.9 20.3 27.5 27.5 0 0 0 28.6 27.7c14.6-.3 26.8-13 28.3-27.3.3 0 0-2.7 0-3-.4-34-22-49-42.7-48.5-8.2.3-16.6 2.8-24 7.8C117 59 112.5 27 85.4 0 56.4 29 55.3 57.8 68.2 95c-7-4-15.6-8.5-23.7-8.2a40.4 40.4 0 0 0-29 12.3A57 57 0 0 0 0 139.9a27.5 27.5 0 0 0 28.5 25.8c15.3-.4 28.1-14 28.5-29.2.5-15.7-12-28.2-27.8-27.8-4 .1-8 1.2-11.5 2.8a31.7 31.7 0 0 1 26.6-15c17.6-.4 35.8 15.7 35.6 43-.3 14.7-32 57.1-43.4 68.6zM142 118.5a17.7 17.7 0 0 1 18.2 17.3v1.5c-.8 10.3-8 18.5-18.5 18.9-26.8.5-23.1-37.3.2-37.7zm-56.6-8c-9-16.7-30.6-61.4 0-96.4 28 32.7 9.6 79 0 96.3zM29 118.4a17.6 17.6 0 0 1 18.3 18.3c-.3 10.7-7.3 19-18.5 19.1-12.8.5-18.6-8.7-18.8-18a18.7 18.7 0 0 1 19-19.4z' style='fill:%23e3d1ad'/%3E%3C/svg%3E");
    height: 40px;
    width: 80px;

    background-color: #240d08;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -20px;
    left: calc(50% - 40px);
  }

  .media_cards {
    box-shadow: 0 0 0 2px #ae967c, 0 0 0 4px #e3d1ad;
    box-sizing: border-box;
    padding: 0;
    overflow: hidden;
    cursor: pointer;

    img {
      object-fit: cover;
      transform: scale(1.1);

      transition: 0.3s;
    }

    &:hover img {
      transform: scale(1.2);
    }
  }
}

.videos_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;

  border-bottom: 1px solid #e3d1ad;
  border-top: 1px solid #e3d1ad;

  padding: 30px 0;
  margin-top: 50px;

  position: relative;

  @media (max-width: $mobile) {
    grid-template-columns: repeat(1, 1fr);
  }

  &::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='20' height='20' style='shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;fill-rule:evenodd;clip-rule:evenodd' viewBox='0 0 170 208.1'%3E%3Cpath d='M36.5 208c18-6.1 34.8-13.7 47.8-26.8 10.7 12.4 27.4 20.3 46 26.8-11-10.9-40.6-55-40.6-69 1.5-27.2 19.7-42 37.3-42.5a30.6 30.6 0 0 1 26.7 14.3 30.6 30.6 0 0 0-11.5-2.1c-7.6.2-14.9 3.3-20.4 9a30 30 0 0 0-8.9 20.3 27.5 27.5 0 0 0 28.6 27.7c14.6-.3 26.8-13 28.3-27.3.3 0 0-2.7 0-3-.4-34-22-49-42.7-48.5-8.2.3-16.6 2.8-24 7.8C117 59 112.5 27 85.4 0 56.4 29 55.3 57.8 68.2 95c-7-4-15.6-8.5-23.7-8.2a40.4 40.4 0 0 0-29 12.3A57 57 0 0 0 0 139.9a27.5 27.5 0 0 0 28.5 25.8c15.3-.4 28.1-14 28.5-29.2.5-15.7-12-28.2-27.8-27.8-4 .1-8 1.2-11.5 2.8a31.7 31.7 0 0 1 26.6-15c17.6-.4 35.8 15.7 35.6 43-.3 14.7-32 57.1-43.4 68.6zM142 118.5a17.7 17.7 0 0 1 18.2 17.3v1.5c-.8 10.3-8 18.5-18.5 18.9-26.8.5-23.1-37.3.2-37.7zm-56.6-8c-9-16.7-30.6-61.4 0-96.4 28 32.7 9.6 79 0 96.3zM29 118.4a17.6 17.6 0 0 1 18.3 18.3c-.3 10.7-7.3 19-18.5 19.1-12.8.5-18.6-8.7-18.8-18a18.7 18.7 0 0 1 19-19.4z' style='fill:%23e3d1ad'/%3E%3C/svg%3E");
    transform: rotate(180deg);
    height: 40px;
    width: 80px;

    background-color: #240d08;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: calc(100% - 20px);
    left: calc(50% - 40px);
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='20' height='20' style='shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;fill-rule:evenodd;clip-rule:evenodd' viewBox='0 0 170 208.1'%3E%3Cpath d='M36.5 208c18-6.1 34.8-13.7 47.8-26.8 10.7 12.4 27.4 20.3 46 26.8-11-10.9-40.6-55-40.6-69 1.5-27.2 19.7-42 37.3-42.5a30.6 30.6 0 0 1 26.7 14.3 30.6 30.6 0 0 0-11.5-2.1c-7.6.2-14.9 3.3-20.4 9a30 30 0 0 0-8.9 20.3 27.5 27.5 0 0 0 28.6 27.7c14.6-.3 26.8-13 28.3-27.3.3 0 0-2.7 0-3-.4-34-22-49-42.7-48.5-8.2.3-16.6 2.8-24 7.8C117 59 112.5 27 85.4 0 56.4 29 55.3 57.8 68.2 95c-7-4-15.6-8.5-23.7-8.2a40.4 40.4 0 0 0-29 12.3A57 57 0 0 0 0 139.9a27.5 27.5 0 0 0 28.5 25.8c15.3-.4 28.1-14 28.5-29.2.5-15.7-12-28.2-27.8-27.8-4 .1-8 1.2-11.5 2.8a31.7 31.7 0 0 1 26.6-15c17.6-.4 35.8 15.7 35.6 43-.3 14.7-32 57.1-43.4 68.6zM142 118.5a17.7 17.7 0 0 1 18.2 17.3v1.5c-.8 10.3-8 18.5-18.5 18.9-26.8.5-23.1-37.3.2-37.7zm-56.6-8c-9-16.7-30.6-61.4 0-96.4 28 32.7 9.6 79 0 96.3zM29 118.4a17.6 17.6 0 0 1 18.3 18.3c-.3 10.7-7.3 19-18.5 19.1-12.8.5-18.6-8.7-18.8-18a18.7 18.7 0 0 1 19-19.4z' style='fill:%23e3d1ad'/%3E%3C/svg%3E");
    height: 40px;
    width: 80px;

    background-color: #240d08;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -20px;
    left: calc(50% - 40px);
  }

  .video_item {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-box-shadow: 0 0 0 2px #ae967c, 0 0 0 4px #e3d1ad;
    box-shadow: 0 0 0 2px #ae967c, 0 0 0 4px #e3d1ad;

    &:hover {
      box-shadow: 0 0 0 2px #ae967c, 0 0 0 4px #e3d1ad, 0 0 10px 5px #e3d1ad;
    }
    iframe {
      width: 100%;
      height: 200px;
    }
    p {
      text-align: center;
      padding: 15px 10px 15px;
      font-size: 16px;
    }
  }
}

.media__cards {
  display: none;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 25px;
  row-gap: 50px;
  margin-bottom: 40px;

  @media (max-width: $laptop) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
  }

  &.visible {
    display: grid;
  }

  &--video {
    @media (max-width: $tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $mobile) {
      grid-template-columns: 1fr;
    }
  }
}

.media__card {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: $card-frame;

  &:hover {
    box-shadow: $card-frame, 0 0 10px 5px $color-2;
    img {
      transform: scale(1.06);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 0.6s ease;
  }

  &-photo {
    display: none;
    height: 20vw;
    max-height: 200px;

    @media (max-width: $laptop) {
      max-height: 250px;
    }

    @media (max-width: $mobile) {
      height: 60vw;
      max-height: unset;
    }

    &.visible {
      display: block;
    }
  }
}

// video
/* ====================================== */

.media__card-video {
  display: block;
  position: relative;
  overflow: visible;
}

.media__video {
  width: 100%;
  height: 200px;

  @media (max-width: $tablet) {
    height: 260px;
  }

  @media (max-width: $mobile) {
    height: 50vw;
  }
}

.media__desc {
  text-align: center;
  padding: 15px 10px 15px;
}

.media__download-link {
  position: absolute;
  display: block;
  bottom: -28px;
  color: $color-2;
  background-color: transparent;
  overflow: hidden;
  padding: 10px 10px 0 0;
  transition: bottom 0.3s ease;

  @media (max-width: $tablet) {
    display: none;
  }

  span {
    position: relative;
  }
}

.lowVisibleVersion {
  .control_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .control_title {
    display: none !important;
  }
  button {
    background-color: #fff !important;
    color: #000;
    outline: none;
    box-shadow: none !important;
    border: 2px solid black !important;

    font-size: 30px;
    &.pressed {
      background-color: #adadad !important;
    }
  }

  .photos_container,
  .videos_container {
    border: none;
    grid-template-columns: repeat(2, 1fr);
    &::after,
    &::before {
      display: none;
    }

    .media_cards {
      box-shadow: none !important;
      border: 2px solid #000;

      p {
        color: #000;
        font-size: 40px;
      }
    }
    .video_item {
      box-shadow: none !important;
      border: 2px solid #000;
      min-height: 400px;

      iframe {
        min-height: 300px !important;
      }

      p {
        color: #000;
        font-size: 40px;
      }
    }
  }
}
