@use '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.section {
  padding-top: 50px;
  margin-bottom: 100px;
  width: 100%;
}

.title {
  @include mixins.h2;
  margin-bottom: 40px;

  &.lowVisibleVersion {
    color: black;
    font-size: 32px;
  }
}

.video {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}
