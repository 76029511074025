@use '../../../../styles/mixins.scss';
@import '../../../../styles/variables.scss';

.aboutkr {
  & .separator {
    @include mixins.separator;

    &.separator-down {
      transform: rotate(180deg);
    }
    margin-bottom: 50px;
  }
}

.title {
  @include mixins.h2;
  margin-bottom: 40px;
}

.wrapper {
  padding-top: 10px;
  margin-bottom: 40px;

  & > span {
    text-indent: 30px;
    display: block;
    margin-bottom: 10px;

    font-size: 18px;
    line-height: 20px;
    font-weight: 300;
    color: $color-3;
    text-align: justify;
  }
}

.image {
  float: left;
  margin: 0 30px 15px 0;
  width: 35%;
  aspect-ratio: 7 / 5;
  box-shadow: $double-frame;
  position: relative;

  @media (max-width: $tablet) {
    width: 50%;
  }

  @media (max-width: $mobile) {
    width: 100%;
    margin-bottom: 25px;
    margin-right: 0;
  }

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
