@use "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.socials {
  padding-top: 20px;
  // padding-bottom: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & .separator {
    margin-bottom: 40px;
    @include mixins.separator;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item {
  display: inline-block;
  padding: 5px;

  &:first-child {
    margin-right: 20px;
  }

  svg {
    // width: 30px;
    // height: 30px;
    border-radius: 7px;
    transition: all 0.2s ease;

    &:hover {
      transform: translate(-2px, -2px);
      box-shadow: 3px 3px 6px 0px rgba($color-3, 0.4);
    }

    &:active {
      transform: translate(0px, 0px);
      box-shadow: 0px 0px 2px 1px rgba($color-3, 0.6);
    }
  }
}
