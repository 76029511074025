@use '../../../../styles/mixins.scss';
@import '../../../../styles/variables.scss';

.partners {
  padding-top: 20px;

  .separator {
    @include mixins.separator;
    margin-bottom: 60px;

    &:last-child {
      bottom: -60px;

      @media (max-width: $tablet) {
        bottom: -75px;
      }
    }

    @media (max-width: $tablet) {
      margin-bottom: 30px;
    }
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  row-gap: 30px;
  margin-bottom: 20px;
  column-gap: 10px;

  @media (max-width: $mobile) {
    padding-top: 10px;
  }

  .lowVisibleVersion {
    transition: 0.3s;

    &:hover {
      border: 2px dashed black;
      background-color: #fff;
      transform: scale(1.3);
      padding: 10px 0;
    }
  }
}

.item {
  flex: 1 1 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;

  @media (max-width: $tablet) {
    padding: 0 10px;
    flex: 1 1 45%;
  }

  @media (max-width: $mobile) {
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  img {
    width: auto;
  }

  // Федерация мужских хоров
  &:nth-child(1) {
    order: 1;

    img {
      width: auto;
      height: 130px;
    }
  }

  // Красноярская митрополия
  &:nth-child(2) {
    order: 3;

    img {
      width: auto;
      max-height: 150px;
    }
  }

  // Аэропорт
  &:nth-child(3) {
    order: 5;

    & a {
      @media (max-width: $tablet) {
        width: 48%;
        justify-content: center;
      }

      @media (max-width: $mobile) {
        width: 60%;
        height: auto;
      }
    }

    img {
      width: auto;
      height: 80px;

      @media (max-width: $tablet) {
        height: 90px;
      }

      @media(max-width: $mobile) {
        height: auto;
      }
    }
  }

  // Красгорпарк
  &:nth-child(4) {
    order: 4;

    img {
      width: auto;
      height: 50px;

      @media (max-width: $mobile) {
        // height: auto;
      }
    }
  }

  // ГРДНТ имени Поленова
  &:nth-child(5) {
    order: 2;
    width: 100%;

    & > a {
      width: 100% !important;
    }

    img {
      width: 100%;
      max-height: 130px;

      @media (max-width: $tablet) {
        max-height: 150px;
      }

      @media (max-width: $mobile) {
        max-height: 140px;
      }

      @media (max-width: 500px) {
        max-height: 130px;
      }
    }

    @media (max-width: $mobile) {
      align-self: center;
    }
  }
}
