@use "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.header {
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;
  z-index: 1000;

  height: 80px;
  box-sizing: border-box;
  background-color: $color-1;
  padding: 10px;
  transition: height 0.15s ease;

  @media (max-width: $laptop) {
    top: 10px;
    left: 5px;
    right: 5px;
    height: 70px;
  }

  @media (max-width: $tablet) {
    position: absolute;
    background-color: transparent;
  }

  &.open {
    @media (max-width: $tablet) {
      width: 100%;
      height: 100%;
      padding-top: 65px;
      padding-bottom: 65px;
      padding-left: 10px;
      padding-right: 10px;
      top: 0;
      right: 25px;
      bottom: 25px;
      left: 0;

      &:before {
        @include mixins.border-small;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:after {
        @include mixins.border-small;
        bottom: 15px;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
      }
    }

    &.header__list {
      @media (max-width: $tablet) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    // & .header__nav {
    //   top: 0;
    //   opacity: 1;
    //   display: block;
    // }

    & .header__decorate {
      opacity: 1;
    }
  }
}

.lowVisibleVersion {
  background-color: #fff;
}

.header__decorate {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  padding: 10px;

  transition: opacity 0.15s ease;

  @media (max-width: $tablet) {
    opacity: 0;
  }

  svg {
    position: absolute;
    z-index: 1000;
  }

  svg:nth-child(1) {
    left: 10px;
    top: 10px;
    transform: rotate(90deg);

    @media (max-width: $tablet) {
      left: 10px;
      top: 65px;
    }
  }

  svg:nth-child(2) {
    right: 10px;
    top: 10px;
    transform: rotate(180deg);

    @media (max-width: $tablet) {
      right: 10px;
      top: 65px;
    }
  }

  svg:nth-child(3) {
    right: 10px;
    bottom: 8px;
    transform: rotate(-90deg);

    @media (max-width: $tablet) {
      right: 10px;
      bottom: 63px;
    }
  }

  svg:nth-child(4) {
    left: 9px;
    bottom: 8px;

    @media (max-width: $tablet) {
      left: 10px;
      bottom: 63px;
    }
  }
}

.header__up_button {
  position: fixed;

  bottom: 30px;
  right: 30px;

  height: 40px;
  width: 40px;

  transition: right 0.3s ease;

  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }

  &.hidden {
    right: -300px;
  }
}

// /* ============================ */

// // Audio button

// // .header__audio {
// //   position: absolute;
// //   right: 40px;
// //   top: 50%;
// //   transform: translateY(-46%);

// //   @media (max-width: $tablet) {
// //     display: none;
// //   }

// //   button {
// //     display: flex;
// //     align-items: center;
// //     justify-content: flex-start;
// //     width: 30px;
// //     height: 30px;
// //     padding: 0;

// //     background-color: transparent;
// //     border: none;

// //     &:hover {
// //       svg {
// //         filter: drop-shadow(0 0 4px $color-3);
// //         path {
// //           // stroke-width: 1.3;
// //         }
// //       }
// //     }

// //     &:active {
// //       svg {
// //         filter: drop-shadow(0 0 0px $color-3);
// //         path {
// //           stroke-width: 1.3;
// //         }
// //       }
// //     }

// //     svg {
// //       width: auto;
// //       height: 70%;

// //       &:first-of-type {
// //         display: none;
// //       }
// //     }

// //     &.played {
// //       svg {
// //         &:first-of-type {
// //           display: block;
// //         }
// //         &:last-of-type {
// //           display: none;
// //         }
// //       }
// //     }
// //   }
// // }
