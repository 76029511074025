@use "../../styles/mixins.scss";
@import "../../styles/variables.scss";

// .slick-list {
//   height: min-content;
//   min-height: 0;
//   max-height: min-content;
// }
.slick-track {
  display: flex;

  // min-width: 0;
}

#chiefs {
  .slick-slider {
    position: relative;
    & .slick-arrow {
      position: absolute;
      top: calc(50vw - 50px);
      z-index: 2;
      font-size: 0;
      width: calc(5.5vw - 0px);
      height: calc(5.5vw - 0px);
      cursor: pointer;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      transition: all 0.2s ease;

      @media (max-width: $mobile - 200px) {
        top: calc(50vw - 30px);

        width: 7vw;
        height: 7vw;
      }

      @media (max-width: $mobile - 350px) {
        top: calc(50vw - 10px);
      }

      &::before {
        display: none;
      }
    }
  }
  .slick-arrow.slick-prev {
    background-image: url("../../assets/svg/arrow-l.svg");
    left: 13%;

    @media (max-width: $mobile - 200px) {
      left: 8%;
    }

    @media (max-width: $mobile - 350px) {
      left: 3%;
    }
  }

  .slick-arrow.slick-next {
    background-image: url("../../assets/svg/arrow-r.svg");
    right: 13%;

    @media (max-width: $mobile - 200px) {
      right: 8%;
    }

    @media (max-width: $mobile - 350px) {
      right: 3%;
    }
  }
}

#experts {
  // Кастомные стрелки
  .slick-slider {
    & .slick-arrow {
      position: absolute;
      top: calc(50vw - 50px);
      z-index: 2;
      font-size: 0;
      width: calc(5.5vw - 0px);
      height: calc(5.5vw - 0px);
      cursor: pointer;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      transition: all 0.2s ease;

      @media (max-width: $mobile - 200px) {
        top: calc(50vw - 30px);

        width: 7vw;
        height: 7vw;
      }

      @media (max-width: $mobile - 350px) {
        top: calc(50vw - 10px);
      }

      &::before {
        display: none;
      }
    }
  }

  .slick-arrow.slick-prev {
    background-image: url("../../assets/svg/arrow-l.svg");
    left: 13%;

    @media (max-width: $mobile - 200px) {
      left: 8%;
    }

    @media (max-width: $mobile - 350px) {
      left: 3%;
    }
  }

  .slick-arrow.slick-next {
    background-image: url("../../assets/svg/arrow-r.svg");
    right: 13%;

    @media (max-width: $mobile - 200px) {
      right: 8%;
    }

    @media (max-width: $mobile - 350px) {
      right: 3%;
    }
  }

  & .slick-arrow {
    top: calc(70vw - 250px);

    @media (max-width: $mobile - 200px) {
      top: calc(70vw - 170px);
    }

    @media (max-width: $mobile - 350px) {
      top: calc(70vw - 120px);
    }
  }
}
