@use "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.frame {
  box-shadow: $card-frame, 4px 4px 20px 0 rgba($black, 0.5);
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 25px 35px;

  outline: 1px solid $color-3;
  outline-offset: -5px;
  background-color: $color-4;

  position: relative;

  @media (max-width: $tablet) {
    box-shadow: none;
    background: none;
    outline: none;
    padding: 30px 0 0 0;
  }

  & > svg {
    position: absolute;
    display: block;

    @media (max-width: $tablet) {
      display: none;
    }

    &:nth-of-type(1) {
      left: 2px;
      top: 2px;
      transform: scale(0.8) rotate(90deg);
    }

    &:nth-of-type(2) {
      right: 2px;
      top: 2px;
      transform: scale(0.8) rotate(180deg);
    }

    &:nth-of-type(3) {
      right: 2px;
      bottom: 2px;
      transform: scale(0.8) rotate(-90deg);
    }

    &:nth-of-type(4) {
      left: 2px;
      bottom: 2px;
      transform: scale(0.8);
    }
  }
}

.lowVisibleVersion {
  background-color: #fff;
  box-shadow: none;
  border: none;
  outline: none;

  .status {
    & > span {
      color: black !important;
    }
  }

  .about {
    &::after {
      background-color: black;
    }
  }
}

.card {
  width: 100%;
  display: grid;
  grid-template-columns: 40% auto;
  grid-template-rows: minmax(20px, min-content) minmax(20px, min-content) auto;
  column-gap: 30px;
  row-gap: 20px;

  @media (max-width: $tablet) {
    grid-template-columns: 100%;
    column-gap: unset;
  }
}

.photo {
  grid-area: 1/1/3/2;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 0px 1px $color-3, 0px 0px 0px 3px $color-4, 0px 0px 0px 4px $color-3;

  &:hover, &:focus, &:active, &:target {
    & .audio {
      bottom: 0;
    }
  }

  @media (max-width: $tablet) {
    grid-area: 2/1/3/2;
  }

  .audio {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    bottom: -50px;
    transition: bottom .3s ease;

    &::-webkit-media-controls-enclosure {
      border-radius: 0px;
      width: 100%;
      background-color: #e3d1ad;
    }
  }

  .white_audio {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    border: 3px solid black;

    &::-webkit-media-controls-enclosure {
      border-radius: 0px;
      width: 100%;
      background-color: #fff;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .white_audio + img {
    box-shadow: none;
    border: 3px solid black;
  }
}

.section_title {
  grid-area: 1/2/2/3;
  position: relative;

  @include mixins.h3;

  @media (max-width: $tablet) {
    grid-area: 1/1/2/2;
    text-align: center;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-2;

    @media (max-width: $tablet) {
      display: none;
    }
  }
}

.about {
  grid-area: 2/2/3/3;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: $tablet) {
    grid-area: 3/1/4/2;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-2;
  }
}

.director {
  display: flex;
  justify-content: space-between;

  font-size: 18px;
  line-height: 24px;

  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    @media (max-width: $tablet) {
      margin-bottom: 15px;
    }
  }

  a {
    width: auto;
    height: min-content;
    padding: 10px 15px 7px;
    display: inline;
    color: $white;
    text-transform: uppercase;
    margin-left: 30px;

    box-shadow: 0px 0px 0px 2px $color-3, 0px 0px 0px 4px $color-4;
    background: radial-gradient(
      circle,
      rgba(144, 101, 85, 1) 0%,
      rgba(69, 38, 26, 1) 100%
    );

    transition: all 0.15s ease;

    @media (max-width: $tablet) {
      margin: 0;
    }

    &:hover {
      box-shadow: 0px 0px 0px 2px $color-3, 0px 0px 0px 4px $color-4,
        0 0 7px 3px rgba($color-2, 0.6);
      background-color: rgba($color-3, 0.2);
    }

    &:active {
      background-color: rgba($color-3, 0.1);
    }
  }
}

.info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: left;
  }
}

.locate {
  display: flex;

  @media (max-width: $tablet) {
    margin-bottom: 15px;
  }

  p {
    font-size: 18px;

    &:first-child {
      margin-right: 10px;
      color: $color-3;
    }
  }
}

.status {
  font-size: 18px;
  text-align: left;

  & > span {
    color: $color-2;

    &:first-child {
      margin-right: 10px;
      color: $color-3;
    }
  }
}

.desc {
  grid-area: 3/1/4/3;

  @include mixins.desc;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  padding-bottom: 3px;
  text-indent: 30px;

  @media (max-width: $tablet) {
    grid-area: 4/1/5/2;
  }
}

.lowVisibleCard {
  display: flex;
  flex-direction: column;

  p, span, h3, a {
    font-size: 30px;
    margin-bottom: 20px;
    border-bottom: none;
    line-height: 1.3 !important;
    letter-spacing: 3px;
    color: #000;

    &::after {
      display: none;
    }
  }

  a {
    background: #fff;
    border: none;
    outline: 2px solid black;
    box-shadow: none;
    transition: 0.3s;

    &:hover {
      background-color: #fff;
      transform: scale(1.3);
    }
  }
}
