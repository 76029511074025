@use '../../../../styles/mixins.scss';
@import '../../../../styles/variables.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(black, .4);
  opacity: 0;
  pointer-events: none;
  backdrop-filter: blur(4px);

  // transform: scale(.5);
  transition: opacity .5s ease;

  &.active {
    // outline: 10px solid green;
    outline-offset: -10px;
    opacity: 1;
    pointer-events: all;
    // transform: scale(1);
  }
}

.nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2010;
  margin: auto;
  width: 100vw;
  height: 100vh;

  // outline: 2px solid red;


  display: flex;
  align-items: center;
  justify-content: center;

  transform: scale(.7);
  opacity: 0;
  pointer-events: none;

  transition: transform .3s ease, opacity .3s ease;

  &.active {
    transform: scale(1);
    opacity: 1;
    pointer-events: all;
  }
}

.list {
  // outline: 2px solid red;
  padding: calc(0vh + 40px) calc(10vw + 20px);

  position: relative;
  border: 1px solid rgba($color-3, 0.5);
  background-color: $color-1;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    background-color: $color-1;
    box-shadow: 0 0 40px 0px $color-3;
    border: 2px solid rgba($color-3, 1);
  }
}

.item {
  position: relative;

  &:not(:last-child) {
    margin-bottom: calc(4vh + 0px);
  }
  &:nth-last-child(2) {
    margin-bottom: 0;
  }

  &::before {
    content: $venzel-2;
    position: absolute;
    left: -25px;
    top: 50%;
    display: block;
    transform: translateY(-50%) scale(2.4) rotateZ(90deg);
    // transition: transform 0.2s ease;
  }

  a {
    color: $white;
    text-transform: uppercase;
    transition: color .3s ease;
    display: block;

    &:hover {
      color: darken($white, 20%);
      text-shadow: 0 0 3px $white;
    }

    &.pressed {
      color: darken($white, 20%);
    }

  }

  &.file {
    &::before {
      display: none;
    }

    a {
      text-transform: none;
      // color:
    }
  }
}

.decorate {
  // position: relative;

  & svg {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;


    &:nth-of-type(1) {
      left: -1.4%;
      top: -3px;
      transform: scale(0.8) rotate(90deg);
    }

    &:nth-of-type(2) {
      right: -1.4%;
      top: -3px;
      transform: scale(0.8) rotate(180deg);
    }

    &:nth-of-type(3) {
      right: -1.4%;
      bottom: -3px;
      transform: scale(0.8) rotate(-90deg);
    }

    &:nth-of-type(4) {
      left: -1.4%;
      bottom: -3px;
      transform: scale(0.8);
    }
  }
}