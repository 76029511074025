@use "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.fmh {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $mobile) {
    flex-wrap: wrap;
    justify-content: space-around;
    display: none;
  }
}

.lowVisibleFmh {
  justify-content: center !important;
}

.head_mobile_wrap {
  position: relative;

  @media (max-width: $mobile) {
    height: auto !important;
  }

  &::after {
    @include mixins.border-small;
    bottom: 20px;
  }

  &.lowVisibleFmh {
    &::after {
      display: none;
    }
  }
}

.head_events {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;

  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &::after {
    content: $venzel-3;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%) scale(3);

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &.lowVisibleFmh {
    &::after {
      display: none;
    }
  }
}

.mobile_fmh_logos {
  display: none;

  @media (max-width: $mobile) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;

    .fmh__logo {
      width: 70px !important;
      height: 70px !important;
    }
  }
}

.bottom_border {
  height: 40px;
  width: 100%;

  @media (max-width: $mobile) {
    margin: 30px 0;
  }
}

.supports_text {
  flex: 1;

  p {
    text-align: center;
  }
}

.fmh__title {
  display: grid;

  @media (max-width: $mobile) {
    margin-bottom: 20px;
    flex: 1 0 100%;
    order: 1;
  }

  .fmh__title_text {
    display: block;
    grid-area: 1/1/2/2;

    font-size: 70px;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;

    padding-left: 10px;
    padding-right: 10px;

    @media (max-width: 1200px) {
      font-size: 50px;
    }
    @media (max-width: 768px) {
      font-size: 45px;
    }
    @media (max-width: 500px) {
      font-size: 35px;
    }

    &_1 {
      text-shadow: 0 0 9px rgba($white, 0.9);

      @media (max-width: 768px) {
        text-shadow: 0 0 4px rgba($white, 0.9);
      }
    }

    &_2 {
      animation: flow 6s linear infinite;
      background: $grad-text;
      background-size: 300%;

      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.lowVisibleVersion {
  text-shadow: none !important;
  background: none !important;

  margin: 50px 0 !important;
}

.fmh__logo {
  flex: 1;
  display: flex;
  aspect-ratio: 2/1;

  width: 100%;
  height: 100%;
  max-width: 170px !important;
  max-height: 170px !important;

  & > img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

@keyframes flow {
  0% {
    background-position: 120% 50%;
  }

  100% {
    background-position: -20% 50%;
  }
}

.notEvent {
  position: relative;

  &::after {
    content: $venzel-3;
    position: absolute;
    padding-top: 50px;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%) scale(3);

    @media(max-width: 1100px) {
      transform: translateX(-50%) scale(2);
    }
  }
}
