@use '../../../../styles/mixins.scss';
@import '../../../../styles/variables.scss';

.burger {
  width: 36px;
  height: 26px;
  outline: none;
  background-color: $color-4;
  padding: 5px;
  border: none;
  // position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  position: fixed;
  right: 30px;
  top: 90px;
  z-index: 6001;

  display: none;

  @media (max-width: $tablet) {
    display: block;
  }
  @media (max-width: $mobile) {
    top: 75px;
  }


  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: $color-2;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 10px;
    }

    &:nth-child(4) {
      top: 20px;
    }
  }

  &__pressed {
    span {
      &:nth-child(1) {
        top: 10px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 10px;
        width: 0%;
        left: 50%;
      }
    }
  }

  &__up {
    top: 30px;
  }
}
