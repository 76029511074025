.voice {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #240d08;
  border: 2px solid #e3d1ad;
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &.played {
    .button {
      &.play {
        width: 0;
      }

      &.pause {
        width: 100%;
      }
    }
  }

  &.lowVisible {
    width: 60px;
    height: 60px;
    border-color: #fff;
  }
}

.button {
  height: 100%;
  transition: width .3s ease;
  cursor: pointer;
  margin-bottom: 0 !important;

  &.play {
    width: 100%;
  }

  &.pause {
    width: 0;
  }

  &.lowVisible {
    & > svg {
      color: #fff;
    }
  }

  & > svg {
    width: 100%;
    height: 100%;
    color: #e3d1ad;
  }
}
