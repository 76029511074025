body {
  color: #e3d1ad;
  background-color: #240d08;
  font-family: Laz;

  &.nonScroll {
    overflow: hidden;

  }
}

.visually-hidden {
  position: absolute !important;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
