@use "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.PageTemplate {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  & main {
    position: relative;
    @include mixins.borders;
    padding-top: 190px;
    padding-bottom: 100px;

    @media (max-width: $laptop) {
      padding-top: 150px;
    }
    @media (max-width: $tablet) {
      padding-top: 110px;
    }
  }

  & .lowVisibleVersion {
    &::before {
      content: none;
    }
    &::after {
      content: none;
    }
  }
}
.temp {
  flex: 1 0 auto;
}
