@use '../../../../styles/mixins.scss';
@import '../../../../styles/variables.scss';

.experts {
  padding-top: 55px;
  padding-bottom: 100px;

  & .separator {
    @include mixins.separator;
    margin-bottom: 60px;

    &:last-child {
      bottom: -60px;

      @media (max-width: $tablet) {
        bottom: -75px;
      }
    }
  }
}

.section_title {
  @include mixins.h2;
  margin-bottom: 30px;
}

.cards {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  row-gap: 30px;

  @media (max-width: $tablet) {
    display: block;
  }
}

.card {
  display: flex;
  flex-flow: row;
  margin-bottom: 20px;
  height: 200px;

  @media (max-width: $tablet) {
    display: flex !important;
    flex-direction: column;
    align-items: center;

    margin-top: 8px;
    margin-bottom: 10px;
    height: unset;
  }
}

.text {
}

.photo {
  box-shadow: $double-frame;
  position: relative;
  top: 10px;

  min-width: 173px;
  width: 173px;
  height: 200px;

  margin-right: 20px;

  position: relative;

  @media (max-width: $tablet) {
    width: 50%;
    height: unset;
    min-width: unset;
    aspect-ratio: 5 / 6;

    margin-bottom: 30px;
    overflow-y: hidden;
    margin-right: unset;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.name {
  @include mixins.name;

  @media (max-width: $tablet) {
    grid-area: unset;
    text-align: center;
    margin-top: 10px;
  }
}

.jobtitle {
  font-size: 20px;

  @media (max-width: $tablet) {
    grid-area: unset;
    text-align: center;
  }
}

.about {
  @include mixins.desc;
  font-size: 18px;
  line-height: 20px;
  text-align: justify;

  @media (max-width: $tablet) {
    grid-area: unset;
    width: 70%;
    margin-top: 10px;
  }

  @media (max-width: $mobile) {
    width: 100%;
  }
}
