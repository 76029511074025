@use "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

// & .separator {
//   @include mixins.separator;

//   @media (max-width: $tablet) {
//     display: block;
//     margin: 50px auto 0;
//   }

//   @media (max-width: $mobile) {
//     margin-top: 50px;
//   }
// }

.participants {
  padding-top: 50px;
  // padding-bottom: 110px;
  min-height: 100%;
  position: relative;

  .separator {
    @include mixins.separator;
  }
}

.section_title {
  @include mixins.h2;
  margin-bottom: 30px;

  @media (max-width: $mobile) {
    // margin-top: 50px;
  }
}

.yearsWrap {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 15px;

  @media (max-width: $tablet) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: $mobile) {
    grid-template-columns: repeat(4, 1fr);
  }

  button {
    width: 100%;
    border: none;
    font-family: "Lazurski";
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    padding: 10px 15px 7px;
    display: inline;
    color: #e3d1ad;
    text-transform: uppercase;
    margin: 5px;
    margin-bottom: 40px;
    -webkit-box-shadow: 0 0 0 2px #ae967c, 0 0 0 4px #2e1005;
    box-shadow: 0 0 0 2px #ae967c, 0 0 0 4px #2e1005;
    background-color: rgba(227, 209, 173, 0.1);
    -webkit-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    white-space: nowrap;
  }
}

.lowVisibleYears {
  button {
    box-shadow: none !important;
    border: 2px solid black !important;
    background-color: #fff !important;

    color: black;
    font-size: 30px;

    &:hover,
    &:focus {
      background-color: #b3b3b3 !important;
    }
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;

  padding-top: 50px;
  padding-bottom: 10px;
  margin-bottom: 100px;

  @media (max-width: $laptop) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 5px 5px 7px 0 rgba($black, 0.3);
  padding: 10px;
  box-shadow: $card-frame;

  &::after {
    content: $venzel-3;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%) scale(2);
  }
}

.photo {
  width: 100%;
  height: 180px;
  margin-bottom: 15px;
  border: 2px solid $white;

  @media (max-width: $laptop) {
    height: 230px;
  }
  @media (max-width: $tablet) {
    height: 270px;
  }
  @media (max-width: $mobile) {
    height: auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.title {
  text-align: center;
  color: $white;

  margin-bottom: 40px;
}

.lowVisibleVersion {
  box-shadow: none;
  border: 2px solid black;

  &::after {
    display: none;
  }

  .photo {
    border: none;
    height: 300px;
  }

  .title {
    color: black;
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: 2px;
  }
}
