.item {
  display: grid;

  grid-template-columns: 1fr 4fr;
  gap: 20px;

  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  img {
    width: 100%;
    aspect-ratio: 1;

    object-fit: cover;
    object-position: center;
  }

  .text_wrapper {
    h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    .control {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 10px;

      a {
        display: block;
        color: #240d08;
        background-color: #e3d1ad;
        font-size: 18px;
        font-weight: 600;

        padding: 8px 12px;
      }
    }
  }
}
