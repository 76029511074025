@use "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.header__nav {
  border: 1px solid $color-2;
  height: 100%;

  @media (max-width: $tablet) {
    position: relative;
    top: -200px;
    // opacity: 0;
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    height: 90px;
    z-index: -1;
    background-color: $color-1;

    @media (max-width: $laptop) {
      top: -10px;
      left: -5px;
      right: -5px;
      height: 70px;
    }
    // @media (max-width: $tablet) {
    //   display: none;
    // }
  }
}

.header__list {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  height: 100%;

  position: relative;

  @media (max-width: $tablet) {
    width: 70%;
    margin: 0 auto;
    flex-direction: column;
    align-items: flex-start;
  }
}

.lowVisibleButton {
  position: absolute;

  top: 0px;
  right: 25px;

  height: 100%;

  display: flex;
  align-items: center;

  cursor: pointer;

  border-radius: 10px;

  & div {
    display: flex;
    align-items: center;
    background-color: #e3d1ad;
    padding: 5px 10px;
    border-radius: 10px;
    p {
      color: #240d08;
      font-size: 25px;
    }
  }

  svg {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
}

.header__item {
  position: relative;

  &:not(:last-child) {
    margin-right: 4vw;

    @media (max-width: $laptop) {
      margin-right: 3vw;
    }
  }

  &::before {
    content: $venzel-1;
    position: absolute;
    left: -2.7vw;
    top: 50%;
    transform: scale(2.5);

    @media (max-width: $laptop) {
      transform: translateY(-30%) scale(1.5);
      left: -2.5vw;
    }

    @media (max-width: $tablet) {
      content: $venzel-2;
      position: absolute;
      left: -25px;
      top: 50%;
      display: block;
      transform: translateY(-50%) scale(2.4) rotateZ(90deg);
      transition: transform 0.2s ease;
    }
  }

  &:first-child {
    @media (max-width: $tablet) {
      button {
        text-align: left;
        padding-left: 0;
      }

      &::before {
        top: 12px;
      }
    }
  }

  &.desktop_hide {
    display: none;

    @media (max-width: $tablet) {
      display: block;
    }
  }

  &.mobile_hide {
    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__ticket {
    display: none;

    &::before {
      @media (max-width: $tablet) {
        top: 12px;
      }
    }

    @media (max-width: $tablet) {
      order: -1;

      button {
        text-align: left;
      }

      & .list_secondary {
        ul {
          padding-left: 0;
        }
        li {
          &::before {
            display: none;
          }
        }
        a {
          color: $color-3;
          text-transform: none;
          font-size: 18px;
        }
      }
    }
  }

  &__translation {
    display: none;
  }
  &__ticket {
    display: none;
  }

  & .prog {
    display: none;
  }

  &__kr {
    @media (max-width: $tablet) {
      display: none !important;
    }
  }

  &__chiefs {
    @media (max-width: $tablet) {
      display: none !important;
    }
  }

  &__aboutfest {
    @media (max-width: $tablet) {
      margin-bottom: 10px;
      order: 15;

      &::before {
        display: none;
      }

      & .list-secondary {
        ul {
          padding-left: 0;
          padding-top: 0;
        }
      }
    }
  }

  &:last-of-type {
    &::after {
      content: $venzel-1;
      position: absolute;
      right: -3vw;
      top: 50%;
      transform: scale(2.5);

      @media (max-width: $laptop) {
        transform: translateY(-30%) scale(1.5);
      }
    }
  }
}

.lowVisibleVersion {
  border: none;
  &::before {
    background-color: #fff;
  }
  button {
    span {
      font-size: 30px;
    }
  }
  .header__list {
    justify-content: space-around;
  }
}

.header__item_low_visible {
  &:hover {
    background-color: #dadada;
    // padding: 20px;
    border: 2px grey dashed;
  }
  &::after,
  &::before {
    display: none !important;
  }
  a,
  button {
    span {
      color: #000;
    }
  }
  .list_secondary {
    background-color: #fff;
    border: none;
    svg {
      display: none;
    }
    li {
      &::after,
      &::before {
        display: none !important;
      }
      a {
        span {
          color: #000;
        }
      }
    }
  }
}

.header__link {
  background-color: transparent;
  border: none;
  color: $white;
  font-family: $font-main;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  white-space: nowrap;
  transition: text-shadow 0.3s ease;
  position: relative;
  z-index: 1000;

  @media (max-width: $laptop) {
    font-size: 16px;
  }

  @media (max-width: $tablet) {
    display: block;
    width: 100%;
  }

  &:hover {
    text-shadow: 0 0 8px $color-2;
  }

  &__fest {
    z-index: 1500;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__ticket {
    z-index: 1500;
  }
}

/* == Раскрывающееся дополнительное меню == */

.list_secondary {
  position: absolute;
  z-index: 1100;
  top: -15px;
  width: 170%;
  left: -55px;

  padding: 5px;

  background-color: $color-4;
  box-shadow: 5px 5px 10px 0px rgba($black, 0.2);
  border: 1px solid $color-3;

  clip-path: circle(0 at 50% 5%);

  transition: clip-path 0.4s ease;

  &__visible {
    clip-path: circle(100%);
  }

  @media (max-width: $laptop) {
    left: -40px;
  }

  @media (max-width: $tablet) {
    position: static;
    clip-path: circle(100%);
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    margin-bottom: 10px;
  }

  ul {
    display: flex;
    flex-direction: column;

    @media (max-width: $tablet) {
      padding-left: 15px;
      padding-top: 10px;
    }
  }

  li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 15px;

    &:hover {
      &::before {
        transform: translate(5px, -50%) scale(2.8) rotateZ(90deg);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
    &::before {
      content: $venzel-2;
      position: absolute;
      left: 5px;
      top: 50%;
      display: block;
      transform: translateY(-50%) scale(2.4) rotateZ(90deg);
      transition: transform 0.2s ease;

      @media (max-width: $tablet) {
        left: -25px;
      }
    }

    @media (max-width: $tablet) {
      padding: 0;
      margin-bottom: calc(4vh - 15px);
    }

    &.file {
      @media (max-width: $tablet) {
        &::before {
          display: none;
        }
      }

      & a {
        color: #ae967c;
        text-transform: none;
        @media (max-width: $tablet) {
          font-size: 18px;
        }
      }
    }
  }

  a {
    color: $white;
    font-family: $font-main;
    font-size: 16px;
    line-height: 18px;
    transition: all 0.3s ease;
    display: block;
    width: 100%;
    padding: 5px;

    @media (max-width: $tablet) {
      text-transform: uppercase;
      padding: 0;
      line-height: 20px;
    }

    &:hover {
      text-shadow: 0 0 3px $color-2;
    }
  }
}

.list_secondary_frame {
  padding: 65px 15px 15px;
  border: 1px solid rgba($color-3, 0.5);
  position: relative;
  z-index: 1000;

  @media (max-width: $tablet) {
    border: none;
    padding: 0;
  }

  & svg {
    position: absolute;
    // outline: 1px solid red;
    display: block;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  svg:nth-of-type(1) {
    left: -1.4%;
    top: -3px;
    transform: scale(0.8) rotate(90deg);
  }
  svg:nth-of-type(2) {
    right: -1.4%;
    top: -3px;
    transform: scale(0.8) rotate(180deg);
  }
  svg:nth-of-type(3) {
    right: -1.4%;
    bottom: -3px;
    transform: scale(0.8) rotate(-90deg);
  }
  svg:nth-of-type(4) {
    left: -1.4%;
    bottom: -3px;

    transform: scale(0.8);
  }
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;
  display: none;

  &__active {
    display: block;
  }
}
