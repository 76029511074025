.container {
  width: 100%;
  max-width: 1300px;

  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  box-sizing: border-box;

  &.tablet {
    // padding-left: 25px;
    // padding-right: 25px;
  }

  &.mobile {
    // outline: 1px solid green;
    padding-left: 30px;
    padding-right: 30px;
  }
}