@use "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.section_title {
  display: none;

  @media (max-width: $tablet) {
    display: block;
    @include mixins.h2;
  }
}

.cards {
  padding-top: 80px;
  padding-bottom: 40px;
  min-width: 0;

  @media (max-width: $tablet) {
    display: flex;
    margin-bottom: 10px !important;
    padding-bottom: 5px;
  }
}

.card {
  display: grid;
  grid-template-columns: 220px auto;
  grid-template-rows: minmax(20px, min-content) minmax(20px, min-content) auto;
  column-gap: 2vw;
  margin-bottom: 120px;

  @media (max-width: $tablet) {
    // grid-template-columns: 1fr;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 0 30px 15px 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.photo {
  grid-area: 1/1/4/2;
  width: 100%;
  height: 100%;
  max-height: 355px;
  position: relative;
  top: 10px;

  @media (max-width: $tablet) {
    grid-area: unset;
    width: 50%;
    max-height: calc(50vw + 145px);
    justify-self: center;
    padding-top: 9px;
    margin-bottom: 25px;
    top: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    box-shadow: $double-frame;
  }
}

.jobtitle {
  grid-area: 1/2/2/3;

  font-size: 18px;
  line-height: 22px;
  color: $color-3;
  text-transform: uppercase;
  margin-bottom: 2px;

  @media (max-width: $tablet) {
    grid-area: unset;
    justify-self: center;
    text-align: center;
    width: 90%;
  }
}

.name {
  grid-area: 2/2/3/3;
  @include mixins.name;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;

  @media (max-width: $tablet) {
    grid-area: unset;
    justify-self: center;
    text-align: center;
  }
}

.desc {
  grid-area: 3/2/4/3;
  @include mixins.desc;
  font-size: 18px;
  line-height: 20px;
  text-align: justify;

  @media (max-width: $tablet) {
    grid-area: unset;
    justify-self: center;
    width: 70%;
  }

  @media (max-width: $mobile) {
    width: 100%;
  }
}

.decor {
  @include mixins.border-big;
}
