.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000, 0.9);

  z-index: 2000;

  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    width: 50%;
    p {
      font-size: 30px;
      text-align: center;
    }
  }
}
