@use "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.history {
  padding-top: 50px;
  // padding-bottom: 110px;
  min-height: 100%;
  position: relative;

  @media (max-width: $tablet) {
    // padding-bottom: 60px;
  }

  .title {
    @include mixins.h2;

    margin-bottom: 30px;

    @media (max-width: $mobile) {
      margin-top: 25px;
    }
  }

  & .separator {
    @include mixins.separator;

    @media (max-width: $tablet) {
      display: block;
      margin: 50px auto 0;
    }

    @media (max-width: $mobile) {
      margin-top: 50px;
    }

    &.separator_down {
      &::before {
        transform: translateX(-50%) scale(3) rotate(180deg);
      }

      @media (max-width: $mobile) {
        margin-top: 50px;
      }
    }
  }
}

.frame {
  box-shadow: $card-frame, 4px 4px 20px 0 rgba($black, 0.5);
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 25px 35px;

  outline: 1px solid $color-3;
  outline-offset: -5px;
  background-color: $color-4;

  position: relative;

  @media (max-width: $tablet) {
    box-shadow: none;
    background: none;
    outline: none;
    padding: 30px 0 0 0;
  }

  @media (max-width: $mobile) {
    margin-top: 20px;
  }

  & > svg {
    position: absolute;
    display: block;

    @media (max-width: $tablet) {
      display: none;
    }

    &:nth-of-type(1) {
      left: 2px;
      top: 2px;
      transform: scale(0.8) rotate(90deg);
    }
    &:nth-of-type(2) {
      right: 2px;
      top: 2px;
      transform: scale(0.8) rotate(180deg);
    }

    &:nth-of-type(3) {
      right: 2px;
      bottom: 2px;
      transform: scale(0.8) rotate(-90deg);
    }

    &:nth-of-type(4) {
      left: 2px;
      bottom: 2px;
      transform: scale(0.8);
    }
  }
}

.content {
  p {
    font-size: 16px;
    line-height: 22px;
    color: $color-3;
    text-indent: 30px;
    text-align: justify;
  }
}

.lowVisibleVersion {
  background-color: #fff;
  box-shadow: none;
  border: none;
  outline: none;

  svg {
    display: none;
  }
}
