@use "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.contacts {
  padding-top: 50px;
  min-height: 100%;
  position: relative;

  & .socials {
    padding-top: 0;
    padding-bottom: 0;

    & .separator {
      display: none;
    }
  }

  & .separator {
    @include mixins.separator;

    @media (max-width: $tablet) {
      display: block;
      margin: 50px auto 0;
    }

    @media (max-width: $mobile) {
      margin-top: 50px;
    }

    &.separator_down {
      margin-bottom: 20px;

      &::before {
        transform: translateX(-50%) scale(3) rotate(180deg);
      }

      @media (max-width: $mobile) {
        margin-top: 20px;
      }
    }
  }
}

.section_title {
  @include mixins.h2;
  margin-bottom: 30px;

  @media (max-width: $mobile) {
    margin-top: 30px;
  }
}

.frame {
  box-shadow: $card-frame, 4px 4px 20px 0 rgba($black, 0.5);
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 25px 35px;

  outline: 1px solid $color-3;
  outline-offset: -5px;
  background-color: $color-4;

  position: relative;

  @media (max-width: $tablet) {
    box-shadow: none;
    background: none;
    outline: none;
    padding: 30px 0 0 0;
  }

  @media (max-width: $mobile) {
    margin-top: 20px;
  }

  & > svg {
    position: absolute;
    display: block;

    @media (max-width: $tablet) {
      display: none;
    }

    &:nth-of-type(1) {
      left: 2px;
      top: 2px;
      transform: scale(0.8) rotate(90deg);
    }

    &:nth-of-type(2) {
      right: 2px;
      top: 2px;
      transform: scale(0.8) rotate(180deg);
    }

    &:nth-of-type(3) {
      right: 2px;
      bottom: 2px;
      transform: scale(0.8) rotate(-90deg);
    }

    &:nth-of-type(4) {
      left: 2px;
      bottom: 2px;
      transform: scale(0.8);
    }
  }
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  height: 190px;

  @media (max-width: $laptop) {
    height: 200px;
  }

  @media (max-width: $tablet) {
    height: auto;
    flex-direction: column;
    align-items: center;
  }
}

.item {
  flex-basis: 32%;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-right: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -10px;
    width: 1px;
    height: 97%;
    background-color: $color-2;
    transform: translateY(-50%);

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &:last-child {
    &::before {
      display: none;
    }
  }

  @media (max-width: $tablet) {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 95%;
      height: 1px;
      background-color: $color-2;
      transform: translateX(-50%);
    }

    &:last-child {
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.point {
  margin-bottom: 15px;
}

.title {
  @include mixins.desc;
  font-size: 16px;
  line-height: 18px;

  @media (max-width: $tablet) {
    margin-bottom: 15px;
  }
}

.name {
  @include mixins.name;
  font-size: 18px;
  // margin-top: auto;

  @media (max-width: $tablet) {
    margin-bottom: 15px;
  }
}

.phone {
  font-size: 18px;
  line-height: 22px;
  color: $white;
  margin-bottom: 5px;
}

.email {
  @media (max-width: $tablet) {
    margin-bottom: 20px;
  }
}

.lowVisibleVersion {
  background-color: #fff;
  box-shadow: none;
  border: none;
  outline: none;

  svg {
    display: none;
  }

  p,
  span,
  a {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 30px;
    color: #000;
  }

  .item {
    border: 1px solid black;
    width: 100%;
    padding: 10px;
    &::after,
    &::before {
      display: none;
    }
  }

  .wrapper {
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}
